import img from "../../images/other_services/pexels-stephanie-ho-994164_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Imbiancatura " prepageName="Servizio" postpageName="Imbiancatura e Verniciatura" />
      <Service
        title="IMBIANCHINO"
        text="Noi di  Manutenpul ci occupiamo dell’imbiancatura in ogni sua parte. 
        Infatti, potrai beneficiare di:"
        img={img}
      >
      <br />
      <br />
      <lu>
        <li>Rasatura</li>
        <li>Stucco</li>
        <li>Imbiancatura interni</li>
        <li>Imbiancatura pareti</li>
        <li>Imbiancatura camere da letto e ogni stanza</li>
        <li>Imbiancatura moderna e ogni stile che desideri</li>
        <li>Decorazioni, come ad esempio lo stucco veneziano, spatolato, antiche terre fiorentine</li>
        <li>Verniciamo anche porte, finestre, cancellate e ringhiere</li>
        <li>Verniciatura facciate condomini e case private </li>
      </lu>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
